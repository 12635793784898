/** @jsx jsx */

import {
  Fragment,
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  useCallback,
  useRef,
} from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { CSSObject, jsx } from '@balance-web/core';
import { Portal } from '@balance-web/portal';
import { useTheme } from '@balance-web/theme';
import { Blanket } from '@balance-web/blanket';
import { makeId } from '@balance-web/utils';

import { SideDrawerBaseContext } from './context';
import { TransitionState } from './types';
import { useSideDrawerControllerContext } from './SideDrawerController';

export type ChildrenProps = {
  Tag: 'form' | 'div';
  id?: string;
  'aria-modal': 'true';
  'aria-label'?: string;
  'aria-labelledby'?: string;
  role: 'dialog';
  ref: MutableRefObject<any>;
  onKeyDown: (event: KeyboardEvent) => void;
  css: any;
  tabIndex: 0;
};

export type SideDrawerBaseProps = {
  children: (childProps: ChildrenProps) => ReactElement;
  id?: string;
  transitionState: TransitionState;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  initialFocusRef?: MutableRefObject<any>;
  background?: 'base' | 'muted';
};

const blanketTransition = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const SideDrawerBase = ({
  children,
  id,
  transitionState,
  initialFocusRef,
  background,
  ...props
}: SideDrawerBaseProps) => {
  const theme = useTheme();
  const containerRef = useRef<any>(null);

  const { onClose } = useSideDrawerControllerContext();

  const Tag = 'div';

  /** Only create aria-labelledby if aria-label is not provided. */
  const headingId = props['aria-label']
    ? undefined
    : props['aria-labelledby'] || makeId(id, 'side-drawer-heading');

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && !event.defaultPrevented) {
      event.preventDefault();
      onClose();
    }
  };

  const activateFocusLock = useCallback(() => {
    if (initialFocusRef && initialFocusRef.current) {
      initialFocusRef.current.focus();
    } else if (containerRef.current) {
      containerRef.current.focus();
    }
  }, [initialFocusRef]);

  const childBaseStyles: CSSObject = {
    backgroundColor: theme.palette.background[background || 'base'],
  };

  const childBaseProps: ChildrenProps = {
    Tag,
    id,
    'aria-modal': 'true',
    role: 'dialog',
    ref: containerRef,
    onKeyDown,
    tabIndex: 0,
    css: childBaseStyles,
    'aria-label': props['aria-label'],
    'aria-labelledby': headingId,
  };

  return (
    <SideDrawerBaseContext.Provider value={{ headingId }}>
      <Portal>
        <Fragment>
          <Blanket
            onClick={onClose}
            css={{ transition: `opacity 150ms linear` }}
            style={blanketTransition[transitionState]}
          />
          <FocusLock
            autoFocus
            returnFocus
            onActivation={activateFocusLock}
            lockProps={{ style: { display: 'contents' } }}
          >
            <RemoveScroll enabled forwardProps>
              {children(childBaseProps)}
            </RemoveScroll>
          </FocusLock>
        </Fragment>
      </Portal>
    </SideDrawerBaseContext.Provider>
  );
};
