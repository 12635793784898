/** @jsx jsx */

import { ButtonHTMLAttributes, MouseEventHandler, forwardRef } from 'react';
import { VisuallyHidden } from '@balance-web/a11y';
import { jsx } from '@balance-web/core';
import { useRawTheme, useTheme } from '@balance-web/theme';

export type ToggleProps = {
  /** Optionally pass "On" and "Off" text for screen readers. */
  a11yText?: { on: string; off: string };
  /** The current checked state. */
  checked?: boolean;
  /** Optionally provide an ID. */
  id?: string;
  /** Handle change events. */
  onChange?: (checked: boolean) => void;
} & Omit<ButtonProps, 'onChange'>;

export const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(
  (
    {
      a11yText = { on: 'On', off: 'Off' },
      checked = false,
      disabled = false,
      id,
      size = 'medium',
      onChange,
      ...props
    },
    ref
  ) => {
    // NOTE: we don't want to _actually_ disable the button element because that
    // would make it inaccessible — it must be focusable for users of assistive
    // tech. By moving the disabled logic to our click handler we provide a
    // solution that supports all users.
    const handleClick: MouseEventHandler = (event) => {
      if (disabled) {
        event.preventDefault();
      } else if (onChange) {
        onChange(!checked);
      }
    };

    return (
      <Button
        aria-checked={checked}
        aria-disabled={disabled}
        id={id}
        onClick={handleClick}
        ref={ref}
        role="switch"
        type="button"
        // appearance props
        size={size}
        {...props}
      >
        <VisuallyHidden>{checked ? a11yText.on : a11yText.off}</VisuallyHidden>
      </Button>
    );
  }
);

Toggle.displayName = 'Toggle';

// Styled Components
// ------------------------------

// TODO move to tokens?
const animationEasing = {
  spring: `cubic-bezier(0.2, 0, 0, 1.6)`,
  easeIn: `cubic-bezier(0.2, 0, 0, 1)`,
  easeOut: `cubic-bezier(0.165, 0.840, 0.440, 1.000)`, // quart
};

type ButtonProps = {
  /** The size of the toggle. */
  size?: 'small' | 'medium';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ size = 'medium', ...props }, ref) => {
    const { palette, shadow } = useTheme();
    const rawTheme = useRawTheme();
    const sizeMap = {
      medium: rawTheme.sizing.xsmall,
      small: rawTheme.sizing.xxsmall,
    };

    let gutter = rawTheme.spacing.xxsmall;
    let trackHeight = sizeMap[size];
    let handleSize = trackHeight - gutter * 2;
    let trackWidth = handleSize * 2;
    let handleTravel = trackWidth / 2 - gutter * 2;

    return (
      <button
        ref={ref}
        css={{
          backgroundColor: palette.toggle.track,
          border: 0,
          borderRadius: 9999,
          boxSizing: 'border-box',
          cursor: 'pointer',
          display: 'block',
          height: trackHeight,
          outline: 0,
          overflow: 'hidden',
          padding: gutter,
          position: 'relative',
          transition: `background 240ms`,
          whiteSpace: 'nowrap',
          width: trackWidth,

          '&[aria-checked="true"]': {
            backgroundColor: palette.toggle.trackChecked,

            '::before': {
              transform: `translateX(${handleTravel}px)`,
            },
          },

          '&[aria-disabled="true"]': {
            backgroundColor: palette.toggle.trackDisabled,
            cursor: 'not-allowed',

            '&[aria-checked="true"]': {
              backgroundColor: palette.toggle.trackDisabledChecked,
            },
          },

          '&.focus-visible': {
            boxShadow: `0 0 0 2px ${palette.global.focusRing}`,
          },

          '::before': {
            height: handleSize,
            width: handleSize,

            backgroundColor: palette.toggle.handle,
            borderRadius: '50%',
            boxShadow: shadow.xsmall,
            content: '" "',
            display: 'block',
            position: 'relative',
            transition: `transform 240ms ${animationEasing.easeOut}`,
          },
        }}
        {...props}
      />
    );
  }
);

Button.displayName = 'Button';
