import React, { useMemo } from 'react';
import type { ReactNode } from 'react';
import { merge } from 'lodash';
import { UniversalNextLink } from '@balance-web/next-link';
import { Core } from '@balance-web/core';
import type { LinkComponent } from '@balance-web/core';
import type { BalanceTheme } from '@balance-web/theme';
import type { DeepPartial } from '@balance-web/utils';
import { cloneDeep } from 'lodash';

import {
  darkTheme,
  darkThemeCSSVars,
  darkThemeRaw,
  lightTheme,
  lightThemeCSSVars,
  lightThemeRaw,
} from '@reckon-web/reckon-balance-theme';

import type { ThemeMode } from './types';

const customTheme: { light: BalanceTheme; dark: BalanceTheme } = {
  // UNDO THIS when csstype patch solution is released.
  // @ts-ignore
  light: { ...lightTheme, name: 'Reckon light' },
  // @ts-ignore
  dark: { ...darkTheme, name: 'Reckon dark' } as any,
};

export type ThemeProviderProps = {
  mode: ThemeMode;
  themeOverrides?: DeepPartial<BalanceTheme>;
  linkComponent?: LinkComponent;
  children?: ReactNode;
};

export const ThemeProvider = ({
  mode,
  themeOverrides,
  linkComponent,
  children,
}: ThemeProviderProps) => {
  const originThemeCopy = useMemo(() => cloneDeep(customTheme[mode]), [mode]);
  const mergedTheme = useMemo(() => merge(originThemeCopy, themeOverrides), [
    originThemeCopy,
    themeOverrides,
  ]);
  const themeCSSVars = useMemo(() => {
    return mode === 'light' ? lightThemeCSSVars : darkThemeCSSVars;
  }, [mode]);

  const themeRaw = useMemo(() => {
    return mode === 'light' ? lightThemeRaw : darkThemeRaw;
  }, [mode]);

  return (
    <Core
      linkComponent={linkComponent || UniversalNextLink}
      theme={mergedTheme}
      themeCSSVars={themeCSSVars}
      themeRaw={themeRaw}
    >
      {children}
    </Core>
  );
};
