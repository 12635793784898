import { useMemo } from 'react';
import { useTheme } from '@balance-web/theme';

import { InputShapeType, InputSizeType } from './types';

export type InputBaseProps = {
  shape: InputShapeType;
  size: InputSizeType;
};

export function useInputStyles({ shape, size }: InputBaseProps) {
  const { palette, typography, radii, sizing, spacing } = useTheme();

  const { fontSizeText, borderRadius, boxSize, paddingX } = useMemo(() => {
    if (size === 'small')
      return {
        fontSizeText: typography.fontSize.small,
        borderRadius: radii.small,
        boxSize: sizing.small, // 32
        paddingX: spacing.medium,
      };

    return {
      fontSizeText: typography.fontSize.medium,
      borderRadius: radii.medium,
      boxSize: sizing.base, // 40
      paddingX: spacing.large,
    };
  }, [
    radii.medium,
    radii.small,
    size,
    sizing.base,
    sizing.small,
    spacing.large,
    spacing.medium,
    typography.fontSize.medium,
    typography.fontSize.small,
  ]);

  const backgroundColor = palette.formInput.background;
  const borderColor = palette.formInput.border;
  const fontWeight = typography.fontWeight.regular;

  return {
    appearance: 'none',
    backgroundColor: backgroundColor,
    border: `1px solid ${borderColor}`,
    borderRadius: shape === 'round' ? 9999 : borderRadius,
    boxSizing: 'border-box',
    color: palette.formInput.text,
    fontFamily: typography.fontFamily.body,
    fontSize: fontSizeText,
    fontWeight,
    outline: 0,
    paddingLeft: paddingX,
    paddingRight: paddingX,
    textAlign: 'inherit',
    width: '100%',

    // things that aren't textarea (input, select etc.)
    height: boxSize,
    lineHeight: `${boxSize}`, // fix vertical alignment of text
    paddingBottom: 0,
    paddingTop: 0,

    // alternate styles for textarea
    'textarea&': {
      lineHeight: typography.leading.tight,
      minHeight: boxSize,
      height: 'auto',
      paddingBottom: '0.66em',
      paddingTop: '0.66em',
      resize: 'vertical',
    },

    ':hover': {
      backgroundColor: palette.formInput.backgroundHovered,
      borderColor: palette.formInput.borderHovered,
    },
    ':focus': {
      backgroundColor: palette.formInput.backgroundFocused,
      borderColor: palette.formInput.borderFocused,
    },

    // disabled state
    ':disabled': {
      backgroundColor: palette.formInput.backgroundDisabled,
      color: palette.formInput.textDisabled,
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },

    // invalid state
    // NOTE: the data attribute is specifically for the current date-picker
    // implementation where we use a button, so `aria-invalid` is not
    // allowed/supported
    '&[data-invalid=true], &[aria-invalid=true]': {
      borderColor: palette.formInput.borderInvalid,

      ':focus': {
        boxShadow: `0 0 0 1px ${palette.formInput.borderInvalid}`,
      },
    },

    // placeholder text
    '&::placeholder': {
      color: palette.formInput.textPlaceholder,
    },
  } as const;
}
