type LoadScriptProps = {
  id: string;
  parent: 'head' | 'body';
  defer?: boolean;
  async?: boolean;
} & ({ src: string } | { content: string });

export function LoadScript({
  id,
  parent = 'head',
  defer,
  async,
  ...props
}: LoadScriptProps) {
  return new Promise<Event>((resolve, reject) => {
    if (typeof window === 'undefined') {
      return Promise.resolve();
    }

    if (typeof document === 'undefined') {
      return Promise.resolve();
    }

    if (document.querySelector(`script[id="${id}"]`)) {
      return Promise.resolve();
    }

    try {
      const s = document.createElement('script');
      s.setAttribute('id', id);
      if (defer) s.setAttribute('defer', 'true');
      if (async) s.setAttribute('async', 'true');

      if ('src' in props) {
        s.setAttribute('src', props.src);
      } else if ('content' in props) {
        s.innerText = props.content;
      }
      s.addEventListener('load', (event) => resolve(event));
      s.addEventListener('error', reject);

      if (parent === 'head' && document.head) document.head.appendChild(s);
      if (parent === 'body' && document.body) document.body.appendChild(s);
    } catch (error) {
      reject(error);
    }
  });
}
