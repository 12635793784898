/** @jsx jsx */

import { ReactElement } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { forwardRefWithAs, useId } from '@balance-web/utils';

import { ModalSize, useModalWidth } from './size';
import { DialogBase } from './DialogBase';

type ContentDialogProps = {
  children: React.ReactNode;
  footer?: ReactElement;
  header?: ReactElement;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  size?: ModalSize;
};

export const ContentDialog = forwardRefWithAs<'div', ContentDialogProps>(
  (
    { children, footer, header, id, isOpen, onClose, size = 'large', ...props },
    ref
  ) => {
    const theme = useTheme();
    const instanceId = useId(id);

    const modalWidth = useModalWidth(size);
    const borderStyle = `1px solid ${theme.palette.global.border}`;

    return (
      <DialogBase
        id={instanceId}
        isOpen={isOpen}
        onClose={onClose}
        width={modalWidth}
        ref={ref}
        enforceLayout
        elevation="modal"
        {...props}
      >
        {header && <div css={{ borderBottom: borderStyle }}>{header}</div>}

        <div css={{ flex: 1, overflowY: 'auto' }}> {children}</div>

        {footer && <div css={{ borderTop: borderStyle }}>{footer}</div>}
      </DialogBase>
    );
  }
);
