/**
 * Do NOT MODIFY THIS FILE OR ANY OTHER FILE IN THE __generated__ DIRECTORY.
 *
 * Generated using StyleDictionary on Fri, 11 Aug 2023 06:19:00 GMT.
 */

export const lightThemeRaw = {
  colors: {
    background: '#ffffff',
    text: '#20262D',
    primary: '#007AFF',
    brand: '#F84184',
    accent: '#3C3391',
    danger: '#E30613',
    warning: '#FFB027',
    success: '#009754',
    info: '#0091EA',
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
  },
  borderWidth: {
    standard: 1,
    large: 2,
  },
  breakpoints: {
    small: 576,
    medium: 768,
    large: 882,
    xlarge: 1200,
  },
  contentWidth: {
    xsmall: 480,
    small: 720,
    medium: 1024,
    large: 1200,
    xlarge: 1440,
  },
  elevation: {
    card: 100,
    dropdown: 200,
    sticky: 300,
    modal: 400,
    popover: 500,
    toast: 600,
  },
  shadow: {
    xsmall: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    small: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    medium: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    large: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    xlarge: '-8px 8px 32px rgba(0, 0, 0, 0.2)',
  },
  palette: {
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    actions: {
      passive: '#585c62',
      active: '#007AFF',
      critical: '#E30613',
    },
    global: {
      accent: '#3C3391',
      border: '#e4e5e6',
      focusRing: 'rgba(0,122,255,0.2)',
      loading: '#007AFF',
    },
    border: {
      standard: '#e4e5e6',
      muted: '#f2f2f2',
      accent: '#d0cee5',
      active: '#c2dfff',
      cautious: '#ffeccb',
      critical: '#f8c3c6',
      informative: '#c2e5fa',
      positive: '#c2e6d6',
    },
    background: {
      base: '#ffffff',
      muted: '#f8f8f9',
      dim: '#f2f2f2',
      shade: '#ebebec',
      dialog: '#ffffff',
      accent: '#e0deed',
      selectableHover: '#fbfbfb',
      selectablePressed: '#f6f6f7',
      selectableSelected: '#f5faff',
      selectableSelectedHover: '#ebf4ff',
      active: '#d6eaff',
      cautious: '#fff2dc',
      critical: '#fbd7d9',
      informative: '#d6edfc',
      positive: '#d6eee4',
      activeMuted: '#cce4ff',
      cautiousMuted: '#ffefd4',
      criticalMuted: '#f9cdd0',
      informativeMuted: '#cce9fb',
      positiveMuted: '#cceadd',
    },
    text: {
      base: '#20262D',
      muted: '#585c62',
      dim: '#909396',
      link: '#007AFF',
      linkHover: '#3689ff',
      linkVisited: '#3C3391',
      accent: '#3C3391',
      active: '#007AFF',
      cautious: '#B35400',
      critical: '#E30613',
      informative: '#0091EA',
      positive: '#009754',
    },
    formInput: {
      background: '#ffffff',
      backgroundHovered: '#f8f8f9',
      backgroundFocused: '#ffffff',
      backgroundDisabled: '#f8f8f9',
      backgroundInvalid: '#fbd7d9',
      border: '#e4e5e6',
      borderHovered: '#e4e5e6',
      borderFocused: '#1A69FE',
      borderDisabled: '#e4e5e6',
      borderInvalid: '#E30613',
      text: '#20262D',
      textDisabled: '#C9CBCD',
      textPlaceholder: '#C9CBCD',
      textInvalid: '#E30613',
    },
    formControl: {
      background: '#ffffff',
      backgroundDisabled: '#e4e5e6',
      border: '#e4e5e6',
      interaction: '#007AFF',
      foregroundChecked: '#ffffff',
      foregroundDisabled: '#909396',
    },
    segmentedControl: {
      track: '#f2f2f2',
      divider: 'rgba(32,38,45,0.16)',
      backgroundSelected: '#ffffff',
      text: '#585c62',
      textFocused: '#20262D',
      textPressed: '#20262D',
      textSelected: '#20262D',
    },
    toggle: {
      track: '#e4e5e6',
      trackChecked: '#007AFF',
      trackDisabled: '#f2f2f2',
      trackDisabledChecked: '#909396',
      handle: 'white',
    },
    listItem: {
      backgroundFocused: '#edeeee',
      backgroundPressed: '#e4e5e6',
      backgroundSelected: '#e4e5e6',
      divider: '#e4e5e6',
      text: '#20262D',
      textFocused: '#20262D',
      textPressed: '#20262D',
      textSelected: '#20262D',
    },
    table: {
      rowBackgroundCautious: '#fffaf2',
      rowBackgroundCritical: '#fdf0f1',
      rowBackgroundPositive: '#f0f9f5',
      rowBackgroundDisabled: '#E0E0E0',
      rowDropBackgroundValid: '#AAD5FF',
      rowDropBackgroundInvalid: '#f9cdd0',
      cellBorderDisabled: '#939393',
    },
    menuItem: {
      backgroundFocused: '#f2f2f2',
      backgroundPressed: '#e4e5e6',
      backgroundSelected: '#f0f7ff',
      divider: '#e4e5e6',
      text: '#20262D',
      textDisabled: '#909396',
      textFocused: '#20262D',
      textPressed: '#20262D',
      textSelected: '#20262D',
    },
    tooltip: {
      background: '#585c62',
      text: '#ffffff',
    },
    button: {
      active: {
        background: '#007AFF',
        backgroundFocused: '#2381ff',
        backgroundPressed: '#006ff2',
      },
      critical: {
        background: '#E30613',
        backgroundFocused: '#ed1c1a',
        backgroundPressed: '#d50007',
      },
      passive: {
        background: '#585c62',
        backgroundFocused: '#5f6369',
        backgroundPressed: '#4e5258',
      },
    },
    actionButton: {
      background: '#ffffff',
      backgroundFocused: '#f2f2f2',
      backgroundPressed: '#e4e5e6',
      backgroundSelected: '#e4e5e6',
      border: '#e4e5e6',
      borderFocused: '#dbdcdd',
      borderPressed: '#c9cbcd',
      borderSelected: '#c9cbcd',
      text: '#585c62',
      textFocused: '#20262D',
      textPressed: '#20262D',
      textSelected: '#20262D',
    },
    interactive: {
      base: {
        background: '#ffffff',
        hover: '#f2f2f2',
        active: '#e4e5e6',
      },
    },
  },
  radii: {
    none: 0,
    xsmall: 4,
    small: 6,
    medium: 8,
    large: 12,
    full: 999,
  },
  sizes: {
    xsmall: {
      fontSizeDisplay: '0.75rem',
      fontSizeText: '0.75rem',
      borderRadius: 4,
      boxSize: 24,
      gap: 4,
      paddingX: 8,
      paddingY: 2,
    },
    small: {
      fontSizeDisplay: '0.75rem',
      fontSizeText: '0.875rem',
      borderRadius: 6,
      boxSize: 32,
      gap: 8,
      paddingX: 12,
      paddingY: 4,
    },
    medium: {
      fontSizeDisplay: '1.125rem',
      fontSizeText: '1rem',
      borderRadius: 8,
      boxSize: 40,
      gap: 8,
      paddingX: 16,
      paddingY: 8,
    },
  },
  sizing: {
    xxsmall: 16,
    xsmall: 24,
    small: 32,
    base: 40,
    medium: 48,
    large: 56,
    xlarge: 72,
  },
  spacing: {
    none: 0,
    xxsmall: 2,
    xsmall: 4,
    small: 8,
    medium: 12,
    large: 16,
    xlarge: 24,
    xxlarge: 32,
  },
  typography: {
    fontFamily: {
      monospace:
        '"SFMono-Medium","SF Mono","Segoe UI Mono","Roboto Mono","Ubuntu Mono", Menlo, Consolas, Courier, monospace',
      body:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      heading:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    },
    fontSize: {
      xsmall: '0.75rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.125rem',
      xlarge: '1.25rem',
      xxlarge: '1.5rem',
      xxxlarge: '1.875rem',
      xxxxlarge: '2.25rem',
      xxxxxlarge: '3rem',
      xxxxxxlarge: '4rem',
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      heavy: 800,
    },
    leading: {
      tighter: 1,
      tight: 1.2,
      base: 1.4,
      loose: 1.6,
      looser: 1.8,
    },
  },
};

export type LightThemeRawType = typeof lightThemeRaw;
