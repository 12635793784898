/** @jsx jsx */

import { InputHTMLAttributes, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { useFieldContext } from '@balance-web/field';
import { useTheme } from '@balance-web/theme';
import { AlertCircleIcon } from '@balance-web/icon';

import { useInputStyles } from './utils';
import { InputSizeType } from './types';
import { Adornment, AdornmentWrapper } from './Adornments';

const validTypes = {
  email: 'email',
  number: 'number',
  password: 'password',
  search: 'search',
  tel: 'tel',
  text: 'text',
  url: 'url',
};

type InputProps = InputHTMLAttributes<HTMLInputElement>;
export type TextInputProps = WithDataAttributeProp<
  {
    // FIXME: temp solution for invalid input w/o displaying an invalid message. we might need a "hideMessage" prop on `Field` to support assistive tech
    invalid?: boolean;
    onChange: NonNullable<InputProps['onChange']>;
    size?: InputSizeType;
    type?: keyof typeof validTypes;
    value: NonNullable<InputProps['value']>;
  } & Omit<InputProps, 'onChange' | 'type' | 'size' | 'value'>
>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { invalid: invalidProp, size = 'medium', type = 'text', data, ...props },
    ref
  ) => {
    const { typography } = useTheme();
    const styles = useInputStyles({ size, shape: 'square' });
    const { invalid, ...a11yProps } = useFieldContext();
    const isInvalid = invalidProp ?? invalid;

    const dataAttributes = buildDataAttributes(data);

    return (
      <AdornmentWrapper size={size} shape="round">
        <input
          aria-invalid={isInvalid}
          ref={ref}
          type={type}
          css={styles}
          {...a11yProps}
          {...props}
          {...dataAttributes}
        />
        {isInvalid && (
          <Adornment
            align="right"
            css={{
              fontSize: typography.fontSize[size],
              pointerEvents: 'none',
            }}
          >
            <AlertCircleIcon size={size} color="critical" />
          </Adornment>
        )}
      </AdornmentWrapper>
    );
  }
);
