/**
 * Do NOT MODIFY THIS FILE OR ANY OTHER FILE IN THE __generated__ DIRECTORY.
 *
 * Generated using StyleDictionary on Fri, 11 Aug 2023 06:19:00 GMT.
 */

export const darkThemeRaw = {
  name: 'Reckon web: Dark',
  colors: {
    background: '#091E42',
    text: '#ffffff',
    primary: '#2684FF',
    brand: '#F84184',
    accent: '#8777D9',
    danger: '#de350c',
    warning: '#ff9a1f',
    success: '#00875a',
    info: '#00b8d9',
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
  },
  borderWidth: {
    standard: 1,
    large: 2,
  },
  breakpoints: {
    small: 576,
    medium: 768,
    large: 992,
    xlarge: 1200,
  },
  contentWidth: {
    xsmall: 480,
    small: 720,
    medium: 1024,
    large: 1200,
    xlarge: 1440,
  },
  elevation: {
    card: 100,
    dropdown: 200,
    sticky: 300,
    modal: 400,
    popover: 500,
    toast: 600,
  },
  shadow: {
    xsmall: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    small: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    medium: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    large: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    xlarge: '-8px 8px 32px rgba(0, 0, 0, 0.2)',
  },
  palette: {
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    actions: {
      passive: '#c2c7d0',
      active: '#2684FF',
      critical: '#de350c',
    },
    global: {
      accent: '#8777D9',
      border: '#273959',
      focusRing: 'rgba(38,132,255,0.2)',
      loading: '#2684FF',
    },
    border: {
      standard: '#273959',
      muted: '#182c4d',
      accent: '#273366',
      active: '#10366f',
      cautious: '#443c3a',
      critical: '#3c2435',
      informative: '#074366',
      positive: '#073748',
    },
    background: {
      base: '#091E42',
      muted: '#102548',
      dim: '#182c4d',
      shade: '#1f3253',
      dialog: '#1d2d53',
      accent: '#1d2c5a',
      selectableHover: '#0e2346',
      selectablePressed: '#13274a',
      selectableSelected: '#0a224a',
      selectableSelectedHover: '#0b2651',
      active: '#0e2e60',
      cautious: '#30323c',
      critical: '#2b2239',
      informative: '#08375a',
      positive: '#082f46',
      activeMuted: '#0f3268',
      cautiousMuted: '#3a373b',
      criticalMuted: '#342337',
      informativeMuted: '#073d60',
      positiveMuted: '#073347',
    },
    text: {
      base: '#ffffff',
      muted: '#c2c7d0',
      dim: '#848fa1',
      link: '#2684FF',
      linkHover: '#4593ff',
      linkVisited: '#8777D9',
      accent: '#8777D9',
      active: '#2684FF',
      cautious: '#ff9a1f',
      critical: '#de350c',
      informative: '#00b8d9',
      positive: '#00875a',
    },
    formInput: {
      background: '#182c4d',
      backgroundHovered: '#182c4d',
      backgroundFocused: '#182c4d',
      backgroundDisabled: '#102548',
      backgroundInvalid: '#2b2239',
      border: '#182c4d',
      borderHovered: '#273959',
      borderFocused: '#2684FF',
      borderDisabled: '#102548',
      borderInvalid: '#2b2239',
      text: '#ffffff',
      textDisabled: '#c2c7d0',
      textPlaceholder: '#848fa1',
      textInvalid: '#de350c',
    },
    formControl: {
      background: '#091E42',
      backgroundDisabled: '#273959',
      border: '#273959',
      interaction: '#2684FF',
      foregroundChecked: '#091E42',
      foregroundDisabled: '#848fa1',
    },
    segmentedControl: {
      track: '#182c4d',
      divider: 'rgba(255,255,255,0.16)',
      backgroundSelected: '#2f3d64',
      text: '#c2c7d0',
      textFocused: '#ffffff',
      textPressed: '#ffffff',
      textSelected: '#ffffff',
    },
    toggle: {
      track: '#273959',
      trackChecked: '#2684FF',
      trackDisabled: '#182c4d',
      trackDisabledChecked: '#848fa1',
      handle: 'white',
    },
    listItem: {
      backgroundFocused: '#1d3051',
      backgroundPressed: '#273959',
      backgroundSelected: '#273959',
      divider: '#273959',
      text: '#ffffff',
      textFocused: '#ffffff',
      textPressed: '#ffffff',
      textSelected: '#ffffff',
    },
    table: {
      rowBackgroundCautious: '#182540',
      rowBackgroundCritical: '#161f3f',
      rowBackgroundPositive: '#082443',
      rowBackgroundDisabled: '#E0E0E0',
      rowDropBackgroundValid: '#AAD5FF',
      rowDropBackgroundInvalid: '#342337',
      cellBorderDisabled: '#939393',
    },
    menuItem: {
      backgroundFocused: '#2b3a5d',
      backgroundPressed: '#384668',
      backgroundSelected: '#1e325d',
      divider: '#273959',
      text: '#ffffff',
      textDisabled: '#848fa1',
      textFocused: '#ffffff',
      textPressed: '#ffffff',
      textSelected: '#ffffff',
    },
    tooltip: {
      background: '#1d2d53',
      text: '#ffffff',
    },
    button: {
      active: {
        background: '#2684FF',
        backgroundFocused: '#378bff',
        backgroundPressed: '#0079f2',
      },
      critical: {
        background: '#de350c',
        backgroundFocused: '#e73e15',
        backgroundPressed: '#d02600',
      },
      passive: {
        background: '#c2c7d0',
        backgroundFocused: '#cacfd8',
        backgroundPressed: '#b6bbc4',
      },
    },
    actionButton: {
      background: '#091E42',
      backgroundFocused: '#182c4d',
      backgroundPressed: '#273959',
      backgroundSelected: '#273959',
      border: '#273959',
      borderFocused: '#304260',
      borderPressed: '#44546f',
      borderSelected: '#44546f',
      text: '#c2c7d0',
      textFocused: '#ffffff',
      textPressed: '#ffffff',
      textSelected: '#ffffff',
    },
    interactive: {
      base: {
        background: '#091E42',
        hover: '#182c4d',
        active: '#273959',
      },
    },
  },
  radii: {
    none: 0,
    xsmall: 4,
    small: 6,
    medium: 8,
    large: 12,
    full: 9999,
  },
  sizes: {
    xsmall: {
      fontSizeDisplay: '0.75rem',
      fontSizeText: '0.75rem',
      borderRadius: 4,
      boxSize: 24,
      gap: 4,
      paddingX: 8,
      paddingY: 2,
    },
    small: {
      fontSizeDisplay: '0.75rem',
      fontSizeText: '0.875rem',
      borderRadius: 6,
      boxSize: 32,
      gap: 8,
      paddingX: 12,
      paddingY: 4,
    },
    medium: {
      fontSizeDisplay: '1.125rem',
      fontSizeText: '1rem',
      borderRadius: 8,
      boxSize: 40,
      gap: 8,
      paddingX: 16,
      paddingY: 8,
    },
  },
  sizing: {
    xxsmall: 16,
    xsmall: 24,
    small: 32,
    base: 40,
    medium: 48,
    large: 56,
    xlarge: 72,
  },
  spacing: {
    none: 0,
    xxsmall: 2,
    xsmall: 4,
    small: 8,
    medium: 12,
    large: 16,
    xlarge: 24,
    xxlarge: 32,
  },
  typography: {
    fontFamily: {
      monospace:
        '"SFMono-Medium"},"SF Mono"},"Segoe UI Mono"},"Roboto Mono"},"Ubuntu Mono"}, Menlo, Consolas, Courier, monospace',
      body:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI"}, Roboto, "Helvetica Neue"}, sans-serif',
      heading:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI"}, Roboto, "Helvetica Neue"}, sans-serif',
    },
    fontSize: {
      xsmall: '0.75rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.125rem',
      xlarge: '1.25rem',
      xxlarge: '1.5rem',
      xxxlarge: '1.875rem',
      xxxxlarge: '2.25rem',
      xxxxxlarge: '3rem',
      xxxxxxlarge: '4rem',
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      heavy: 800,
    },
    leading: {
      tighter: 1,
      tight: 1.2,
      base: 1.4,
      loose: 1.6,
      looser: 1.8,
    },
  },
};

export type darkThemeRawType = typeof darkThemeRaw;
