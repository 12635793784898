// ts-gql-integrity:e5ad2a3098734d01cc5347d533c454d1
/*
ts-gql-meta-begin
{
  "hash": "c5ce6e32e39f613514d873cdc8ab13cb"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type GetBooksQueryQueryVariables = {};


type GetBooksQueryQuery = (
  { readonly __typename: 'Query' }
  & { readonly books: ReadonlyArray<(
    { readonly __typename: 'Book' }
    & Pick<SchemaTypes.Book, 'id' | 'name' | 'country' | 'status'>
    & { readonly additionalDetails: SchemaTypes.Maybe<(
      { readonly __typename: 'BookAdditionalDetails' }
      & Pick<SchemaTypes.BookAdditionalDetails, 'id' | 'r1Created'>
    )> }
  )> }
);


export type type = TypedDocumentNode<{
  type: "query";
  result: GetBooksQueryQuery;
  variables: GetBooksQueryQueryVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    GetBooksQuery: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"GetBooksQuery\"},\"variableDefinitions\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"books\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"country\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"status\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"additionalDetails\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"r1Created\"},\"arguments\":[],\"directives\":[]}]}}]}}]}}]}")
