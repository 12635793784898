import {
  Field,
  Form,
  ScalarField,
  ValidatedFormValueFromScalarField,
  ValidationFnInObjectValidation,
  validation,
} from '@magical-forms/react-next';

let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateHelpers = {
  error(message: string) {
    throw new ValidationError(message);
  },
  required<Value extends string | null | undefined>(
    value: Value,
    fieldName?: string
  ): asserts value is NonNullable<Value> {
    if (!value || !value.length) {
      validateHelpers.error(
        fieldName ? `${fieldName} is required` : 'Required'
      );
    }
  },
  nonNullable<Value>(value: Value): asserts value is NonNullable<Value> {
    if (value == null) {
      validateHelpers.error('Required');
    }
  },
  minLength<Value extends string>(value: Value, len: number) {
    if (value.length < len) {
      validateHelpers.error(`Must be at least ${len} characters`);
    }
  },
  maxLength<Value extends string>(value: Value, len: number) {
    if (value.length > len) {
      validateHelpers.error(`Must be at most ${len} characters`);
    }
  },
  float(value: string): number {
    let num = Number(value);
    if (isNaN(num)) {
      validateHelpers.error('Must be a number');
    }
    return num;
  },
  min(value: number, min: number) {
    if (value < min) {
      validateHelpers.error(`Must be greater than or equal to ${min}`);
    }
  },
  max(value: number, max: number) {
    if (value > max) {
      validateHelpers.error(`Must be less than or equal to ${max}`);
    }
  },
  percent(value: number) {
    validateHelpers.min(value, 0);
    validateHelpers.max(value, 100);
  },
  email(value: string) {
    if (!emailRegex.test(value)) {
      validateHelpers.error('Enter a valid email address');
    }
  },
  currency(value: string | number): asserts value is number {
    if (typeof value === 'string') {
      validateHelpers.error('Must be an amount of currency');
    }
  },
  positiveNumber(value: number) {
    if (value < 0) {
      validateHelpers.error('Must not be a negative value');
    }
  },
  object: function objectValidateFn<
    TScalarField extends ScalarField,
    ObjectValue
  >(
    cb: (
      value: ValidatedFormValueFromScalarField<TScalarField>,
      objectValue: ObjectValue
    ) => ValidatedFormValueFromScalarField<TScalarField>
  ): ValidationFnInObjectValidation<TScalarField, ObjectValue> {
    return (value, objectValue) => {
      try {
        return validation.valid(cb(value, objectValue));
      } catch (err) {
        if (err instanceof ValidationError) {
          return validation.invalid(err.message);
        }
        throw err;
      }
    };
  },
};

type ScalarValidationFn<Value, ValidatedValue extends Value> = (
  value: Value
) =>
  | { readonly validity: 'valid'; readonly value: ValidatedValue }
  | { readonly validity: 'invalid'; readonly error: string };

function validateFn<Value, ValidatedValue extends Value>(
  cb: (value: Value) => ValidatedValue
): ScalarValidationFn<Value, ValidatedValue> {
  return (value) => {
    try {
      return validation.valid(cb(value));
    } catch (err) {
      if (err instanceof ValidationError) {
        return validation.invalid(err.message);
      }
      throw err;
    }
  };
}

class ValidationError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(message: string) {
    super(message);
  }
}

export const validate: typeof validateHelpers &
  typeof validateFn = Object.assign(validateFn, validateHelpers);

function getValidationMessagesInternal(form: Form<Field>, messages: string[]) {
  if (form._field.kind === 'scalar') {
    if (
      'error' in form &&
      form.error &&
      'touched' in form.state &&
      form.state.touched
    ) {
      messages.push((form as any).error);
    }
    return;
  }
  if (form._field.kind === 'array') {
    (form as any).elements?.forEach((form: any) => {
      if ((form as any).fields) {
        Object.values((form as any).fields)?.forEach((form) => {
          getValidationMessagesInternal(form as any, messages);
        });
      } else {
        getValidationMessagesInternal(form as any, messages);
      }
    });
  }
  if (form._field.kind === 'object') {
    Object.values((form as any).fields).forEach((form) => {
      getValidationMessagesInternal(form as any, messages);
    });
  }
}

export function getValidationMessages(
  form: Form<Field>
): {
  validationMessages: string[];
  hasValidationMessages: boolean;
} {
  let validationMessages: string[] = [];
  getValidationMessagesInternal(form, validationMessages);
  return {
    validationMessages,
    hasValidationMessages: !!validationMessages.length,
  };
}
