import React from 'react';
import '@reckon-web/gql-api-client';

import { GqlApiClientProvider } from '@reckon-web/gql-api-client';

import { Config } from '../Config';
import { result as introspectionQueryResultData } from '../../../__generated__/ts-gql/@introspection';
import { refreshAuthTokenMutation } from '../Auth/refreshAuthTokenMutation';

type ApolloProviderProps = { children: React.ReactNode };
export const ApolloProvider = ({ children }: ApolloProviderProps) => {
  return (
    <GqlApiClientProvider
      url={Config.APPLICATION_APOLLO_CLIENT_API_URL}
      name={Config.APPLICATION_APOLLO_CLIENT_NAME}
      operationPrefixNameForAddingToRawLogOutputs={
        Config.APPLICATION_APOLLO_CLIENT_OPERATIONPREFIX_SHORTNAME
      }
      version={Config.APPLICATION_VERSION}
      schema={introspectionQueryResultData}
      onTokenRefresh={refreshAuthTokenMutation}
    >
      {children}
    </GqlApiClientProvider>
  );
};
