/**
 * Do NOT MODIFY THIS FILE OR ANY OTHER FILE IN THE __generated__ DIRECTORY.
 *
 * Generated using StyleDictionary on Fri, 11 Aug 2023 06:19:00 GMT.
 */

export const lightTheme = {
  colors: {
    background: 'var(--colors-background)',
    text: 'var(--colors-text)',
    primary: 'var(--colors-primary)',
    brand: 'var(--colors-brand)',
    accent: 'var(--colors-accent)',
    danger: 'var(--colors-danger)',
    warning: 'var(--colors-warning)',
    success: 'var(--colors-success)',
    info: 'var(--colors-info)',
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
  },
  borderWidth: {
    standard: 'var(--border-width-standard)',
    large: 'var(--border-width-large)',
  },
  breakpoints: {
    small: 'var(--breakpoints-small)',
    medium: 'var(--breakpoints-medium)',
    large: 'var(--breakpoints-large)',
    xlarge: 'var(--breakpoints-xlarge)',
  },
  contentWidth: {
    xsmall: 'var(--content-width-xsmall)',
    small: 'var(--content-width-small)',
    medium: 'var(--content-width-medium)',
    large: 'var(--content-width-large)',
    xlarge: 'var(--content-width-xlarge)',
  },
  elevation: {
    card: 'var(--elevation-card)',
    dropdown: 'var(--elevation-dropdown)',
    sticky: 'var(--elevation-sticky)',
    modal: 'var(--elevation-modal)',
    popover: 'var(--elevation-popover)',
    toast: 'var(--elevation-toast)',
  },
  shadow: {
    xsmall: 'var(--shadow-xsmall)',
    small: 'var(--shadow-small)',
    medium: 'var(--shadow-medium)',
    large: 'var(--shadow-large)',
    xlarge: 'var(--shadow-xlarge)',
  },
  palette: {
    decorative: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    variant: [
      '#BDE7FF',
      '#B1FAE0',
      '#FFF0B3',
      '#FFB7B2',
      '#FFB1D8',
      '#CBC1F4',
      '#AFD5FF',
    ],
    actions: {
      passive: 'var(--palette-actions-passive)',
      active: 'var(--palette-actions-active)',
      critical: 'var(--palette-actions-critical)',
    },
    global: {
      accent: 'var(--palette-global-accent)',
      border: 'var(--palette-global-border)',
      focusRing: 'var(--palette-global-focus-ring)',
      loading: 'var(--palette-global-loading)',
    },
    border: {
      standard: 'var(--palette-border-standard)',
      muted: 'var(--palette-border-muted)',
      accent: 'var(--palette-border-accent)',
      active: 'var(--palette-border-active)',
      cautious: 'var(--palette-border-cautious)',
      critical: 'var(--palette-border-critical)',
      informative: 'var(--palette-border-informative)',
      positive: 'var(--palette-border-positive)',
    },
    background: {
      base: 'var(--palette-background-base)',
      muted: 'var(--palette-background-muted)',
      dim: 'var(--palette-background-dim)',
      shade: 'var(--palette-background-shade)',
      dialog: 'var(--palette-background-dialog)',
      accent: 'var(--palette-background-accent)',
      selectableHover: 'var(--palette-background-selectable-hover)',
      selectablePressed: 'var(--palette-background-selectable-pressed)',
      selectableSelected: 'var(--palette-background-selectable-selected)',
      selectableSelectedHover:
        'var(--palette-background-selectable-selected-hover)',
      active: 'var(--palette-background-active)',
      cautious: 'var(--palette-background-cautious)',
      critical: 'var(--palette-background-critical)',
      informative: 'var(--palette-background-informative)',
      positive: 'var(--palette-background-positive)',
      activeMuted: 'var(--palette-background-active-muted)',
      cautiousMuted: 'var(--palette-background-cautious-muted)',
      criticalMuted: 'var(--palette-background-critical-muted)',
      informativeMuted: 'var(--palette-background-informative-muted)',
      positiveMuted: 'var(--palette-background-positive-muted)',
    },
    text: {
      base: 'var(--palette-text-base)',
      muted: 'var(--palette-text-muted)',
      dim: 'var(--palette-text-dim)',
      link: 'var(--palette-text-link)',
      linkHover: 'var(--palette-text-link-hover)',
      linkVisited: 'var(--palette-text-link-visited)',
      accent: 'var(--palette-text-accent)',
      active: 'var(--palette-text-active)',
      cautious: 'var(--palette-text-cautious)',
      critical: 'var(--palette-text-critical)',
      informative: 'var(--palette-text-informative)',
      positive: 'var(--palette-text-positive)',
    },
    formInput: {
      background: 'var(--palette-form-input-background)',
      backgroundHovered: 'var(--palette-form-input-background-hovered)',
      backgroundFocused: 'var(--palette-form-input-background-focused)',
      backgroundDisabled: 'var(--palette-form-input-background-disabled)',
      backgroundInvalid: 'var(--palette-form-input-background-invalid)',
      border: 'var(--palette-form-input-border)',
      borderHovered: 'var(--palette-form-input-border-hovered)',
      borderFocused: 'var(--palette-form-input-border-focused)',
      borderDisabled: 'var(--palette-form-input-border-disabled)',
      borderInvalid: 'var(--palette-form-input-border-invalid)',
      text: 'var(--palette-form-input-text)',
      textDisabled: 'var(--palette-form-input-text-disabled)',
      textPlaceholder: 'var(--palette-form-input-text-placeholder)',
      textInvalid: 'var(--palette-form-input-text-invalid)',
    },
    formControl: {
      background: 'var(--palette-form-control-background)',
      backgroundDisabled: 'var(--palette-form-control-background-disabled)',
      border: 'var(--palette-form-control-border)',
      interaction: 'var(--palette-form-control-interaction)',
      foregroundChecked: 'var(--palette-form-control-foreground-checked)',
      foregroundDisabled: 'var(--palette-form-control-foreground-disabled)',
    },
    segmentedControl: {
      track: 'var(--palette-segmented-control-track)',
      divider: 'var(--palette-segmented-control-divider)',
      backgroundSelected:
        'var(--palette-segmented-control-background-selected)',
      text: 'var(--palette-segmented-control-text)',
      textFocused: 'var(--palette-segmented-control-text-focused)',
      textPressed: 'var(--palette-segmented-control-text-pressed)',
      textSelected: 'var(--palette-segmented-control-text-selected)',
    },
    toggle: {
      track: 'var(--palette-toggle-track)',
      trackChecked: 'var(--palette-toggle-track-checked)',
      trackDisabled: 'var(--palette-toggle-track-disabled)',
      trackDisabledChecked: 'var(--palette-toggle-track-disabled-checked)',
      handle: 'var(--palette-toggle-handle)',
    },
    listItem: {
      backgroundFocused: 'var(--palette-list-item-background-focused)',
      backgroundPressed: 'var(--palette-list-item-background-pressed)',
      backgroundSelected: 'var(--palette-list-item-background-selected)',
      divider: 'var(--palette-list-item-divider)',
      text: 'var(--palette-list-item-text)',
      textFocused: 'var(--palette-list-item-text-focused)',
      textPressed: 'var(--palette-list-item-text-pressed)',
      textSelected: 'var(--palette-list-item-text-selected)',
    },
    table: {
      rowBackgroundCautious: 'var(--palette-table-row-background-cautious)',
      rowBackgroundCritical: 'var(--palette-table-row-background-critical)',
      rowBackgroundPositive: 'var(--palette-table-row-background-positive)',
      rowBackgroundDisabled: 'var(--palette-table-row-background-disabled)',
      rowDropBackgroundValid: 'var(--palette-table-row-drop-background-valid)',
      rowDropBackgroundInvalid:
        'var(--palette-table-row-drop-background-invalid)',
      cellBorderDisabled: 'var(--palette-table-cell-border-disabled)',
    },
    menuItem: {
      backgroundFocused: 'var(--palette-menu-item-background-focused)',
      backgroundPressed: 'var(--palette-menu-item-background-pressed)',
      backgroundSelected: 'var(--palette-menu-item-background-selected)',
      divider: 'var(--palette-menu-item-divider)',
      text: 'var(--palette-menu-item-text)',
      textDisabled: 'var(--palette-menu-item-text-disabled)',
      textFocused: 'var(--palette-menu-item-text-focused)',
      textPressed: 'var(--palette-menu-item-text-pressed)',
      textSelected: 'var(--palette-menu-item-text-selected)',
    },
    tooltip: {
      background: 'var(--palette-tooltip-background)',
      text: 'var(--palette-tooltip-text)',
    },
    button: {
      active: {
        background: 'var(--palette-button-active-background)',
        backgroundFocused: 'var(--palette-button-active-background-focused)',
        backgroundPressed: 'var(--palette-button-active-background-pressed)',
      },
      critical: {
        background: 'var(--palette-button-critical-background)',
        backgroundFocused: 'var(--palette-button-critical-background-focused)',
        backgroundPressed: 'var(--palette-button-critical-background-pressed)',
      },
      passive: {
        background: 'var(--palette-button-passive-background)',
        backgroundFocused: 'var(--palette-button-passive-background-focused)',
        backgroundPressed: 'var(--palette-button-passive-background-pressed)',
      },
    },
    actionButton: {
      background: 'var(--palette-action-button-background)',
      backgroundFocused: 'var(--palette-action-button-background-focused)',
      backgroundPressed: 'var(--palette-action-button-background-pressed)',
      backgroundSelected: 'var(--palette-action-button-background-selected)',
      border: 'var(--palette-action-button-border)',
      borderFocused: 'var(--palette-action-button-border-focused)',
      borderPressed: 'var(--palette-action-button-border-pressed)',
      borderSelected: 'var(--palette-action-button-border-selected)',
      text: 'var(--palette-action-button-text)',
      textFocused: 'var(--palette-action-button-text-focused)',
      textPressed: 'var(--palette-action-button-text-pressed)',
      textSelected: 'var(--palette-action-button-text-selected)',
    },
    interactive: {
      base: {
        background: 'var(--palette-interactive-base-background)',
        hover: 'var(--palette-interactive-base-hover)',
        active: 'var(--palette-interactive-base-active)',
      },
    },
  },
  radii: {
    none: 'var(--radii-none)',
    xsmall: 'var(--radii-xsmall)',
    small: 'var(--radii-small)',
    medium: 'var(--radii-medium)',
    large: 'var(--radii-large)',
    full: 'var(--radii-full)',
  },
  sizes: {
    xsmall: {
      fontSizeDisplay: 'var(--sizes-xsmall-font-size-display)',
      fontSizeText: 'var(--sizes-xsmall-font-size-text)',
      borderRadius: 'var(--sizes-xsmall-border-radius)',
      boxSize: 'var(--sizes-xsmall-box-size)',
      gap: 'var(--sizes-xsmall-gap)',
      paddingX: 'var(--sizes-xsmall-padding-x)',
      paddingY: 'var(--sizes-xsmall-padding-y)',
    },
    small: {
      fontSizeDisplay: 'var(--sizes-small-font-size-display)',
      fontSizeText: 'var(--sizes-small-font-size-text)',
      borderRadius: 'var(--sizes-small-border-radius)',
      boxSize: 'var(--sizes-small-box-size)',
      gap: 'var(--sizes-small-gap)',
      paddingX: 'var(--sizes-small-padding-x)',
      paddingY: 'var(--sizes-small-padding-y)',
    },
    medium: {
      fontSizeDisplay: 'var(--sizes-medium-font-size-display)',
      fontSizeText: 'var(--sizes-medium-font-size-text)',
      borderRadius: 'var(--sizes-medium-border-radius)',
      boxSize: 'var(--sizes-medium-box-size)',
      gap: 'var(--sizes-medium-gap)',
      paddingX: 'var(--sizes-medium-padding-x)',
      paddingY: 'var(--sizes-medium-padding-y)',
    },
  },
  sizing: {
    xxsmall: 'var(--sizing-xxsmall)',
    xsmall: 'var(--sizing-xsmall)',
    small: 'var(--sizing-small)',
    base: 'var(--sizing-base)',
    medium: 'var(--sizing-medium)',
    large: 'var(--sizing-large)',
    xlarge: 'var(--sizing-xlarge)',
  },
  spacing: {
    none: 'var(--spacing-none)',
    xxsmall: 'var(--spacing-xxsmall)',
    xsmall: 'var(--spacing-xsmall)',
    small: 'var(--spacing-small)',
    medium: 'var(--spacing-medium)',
    large: 'var(--spacing-large)',
    xlarge: 'var(--spacing-xlarge)',
    xxlarge: 'var(--spacing-xxlarge)',
  },
  typography: {
    fontFamily: {
      monospace:
        '"SFMono-Medium","SF Mono","Segoe UI Mono","Roboto Mono","Ubuntu Mono", Menlo, Consolas, Courier, monospace',
      body:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      heading:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    },
    fontSize: {
      xsmall: '0.75rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.125rem',
      xlarge: '1.25rem',
      xxlarge: '1.5rem',
      xxxlarge: '1.875rem',
      xxxxlarge: '2.25rem',
      xxxxxlarge: '3rem',
      xxxxxxlarge: '4rem',
    },
    fontWeight: {
      regular: 'var(--typography-font-weight-regular)',
      medium: 'var(--typography-font-weight-medium)',
      semibold: 'var(--typography-font-weight-semibold)',
      bold: 'var(--typography-font-weight-bold)',
      heavy: 'var(--typography-font-weight-heavy)',
    },
    leading: {
      tighter: 'var(--typography-leading-tighter)',
      tight: 'var(--typography-leading-tight)',
      base: 'var(--typography-leading-base)',
      loose: 'var(--typography-leading-loose)',
      looser: 'var(--typography-leading-looser)',
    },
  },
};

export type LightThemeType = typeof lightTheme;
