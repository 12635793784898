/** @jsx jsx */
import React from 'react';
import { AppProps } from 'next/app';
import { MarkingProvider } from '@markings/react';
import { init as initialiseSentry } from '@sentry/react';
import { jsx } from '@balance-web/core';

import { LoadScript } from '@reckon-web/load-script';
import { LoadGoogleTagManagerService } from '@reckon-web/google-tag-manager';
import { LoadBranchIoService } from '@reckon-web/branchio';
import { useEnsureSupportedBrowser } from '@reckon-web/next-unsupported-browsers';

import { ServicesProvider } from '../services';
import { Config } from '../services/Config';
import { AppWindow } from '../components/app/AppLayout';
import { BalanceProvider } from '../components/app/Balance';
import { DevLauncherProvider } from '../components/app/DevLauncher';
import { MetaHead } from '../components/app/MetaHead';

initialiseSentry({
  dsn: Config.APPLICATION_SENTRY_DSN,
  environment: Config.STAGE,
});

(async () => {
  if ('undefined' === typeof window) return;

  LoadScript({
    parent: 'head',
    id: `newrelic-${Config.STAGE}`,
    src: `/newrelic-${Config.STAGE}.js`,
  });
  LoadGoogleTagManagerService({
    apiKey: Config.APPLICATION_GOOGLE_GTM_APIKEY,
  });
  LoadBranchIoService({
    apiKey: Config.APPLICATION_BRANCHIO_APIKEY,
  });
})();

function App({ Component, pageProps, router }: AppProps) {
  useEnsureSupportedBrowser({
    errorPagePath: '/unsupported-browser',
    currentPath: router.pathname,
    gotoErrorPage: (pathname) => router.push({ pathname }),
  });

  return (
    <React.StrictMode>
      <MarkingProvider enabled={Config.APPLICATION_FEATURE_USE_MARKINGS}>
        <BalanceProvider>
          <AppWindow>
            <ServicesProvider>
              <MetaHead
                appName={Config.APPLICATION_DISPLAY_NAME}
                version={Config.APPLICATION_VERSION?.toString()}
              />
              <DevLauncherProvider
                visible={Config.APPLICATION_FEATURE_USE_DEVLAUNCHER}
              >
                <Component {...pageProps} />
              </DevLauncherProvider>
            </ServicesProvider>
          </AppWindow>
        </BalanceProvider>
      </MarkingProvider>
    </React.StrictMode>
  );
}

export default App;
