import React from 'react';
import type { PropsWithChildren } from 'react';

import { ErrorBoundaryProvider } from '@reckon-web/error-boundary';

import { SiteRoutesProvider } from './Navigation';
import { AuthProvider, AuthenticatedUserProvider } from './Auth';
import { ApolloProvider } from './Api';

/**
 * Wraps up all the various Service Providers
 *
 * If you need more global context, providers and hooks, then Providers get instantiated here
 */
export function ServicesProvider({ children }: PropsWithChildren<{}>) {
  return (
    <ErrorBoundaryProvider>
      <AuthProvider>
        <ApolloProvider>
          <AuthenticatedUserProvider>
            <SiteRoutesProvider>{children}</SiteRoutesProvider>
          </AuthenticatedUserProvider>
        </ApolloProvider>
      </AuthProvider>
    </ErrorBoundaryProvider>
  );
}
