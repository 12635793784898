/** @jsx jsx */

import { Children, ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import { Stack, StackProps } from '@balance-web/stack';
import {
  DefaultTextPropsProvider,
  Text,
  TextProps,
  useDefaultTextProps,
} from '@balance-web/text';
import { useTheme } from '@balance-web/theme';

const typeToElement = {
  bullet: 'ul',
  number: 'ol',
  alpha: 'ol',
} as const;

export type TextListProps = {
  children: StackProps['children'];
  size?: TextProps['size'];
  gap?: StackProps['gap'];
  color?: TextProps['color'];
  type?: keyof typeof typeToElement;
  data?: StackProps['data'];
};

export const TextList = ({
  children,
  size: sizeProp,
  color: colorProp,
  gap = 'small',
  type = 'bullet',
  data,
}: TextListProps) => {
  const { typography } = useTheme();
  const { size, color } = useDefaultTextProps({
    size: sizeProp,
    color: colorProp,
  });
  const listItems = Children.toArray(children);
  const lastNumberLength =
    type === 'number' ? listItems.length.toString().length : -1;

  return (
    <DefaultTextPropsProvider size={size} color={color}>
      <Stack as={typeToElement[type]} gap={gap} data={data}>
        {Children.map(listItems, (listItem, index) => {
          return (
            <Flex as="li">
              <Text size={size} color={color}>
                <Flex
                  alignItems={type === 'bullet' ? 'center' : undefined}
                  userSelect="none"
                  height={`${typography.leading.base}em`}
                  aria-hidden
                >
                  {(() => {
                    if (type === 'number') {
                      return (
                        <CharacterBullet length={lastNumberLength}>
                          {index + 1}
                        </CharacterBullet>
                      );
                    }

                    if (type === 'alpha') {
                      return (
                        <CharacterBullet>
                          {numberToAlpha(index + 1)}
                        </CharacterBullet>
                      );
                    }

                    return <Bullet size={size} />;
                  })()}
                </Flex>
              </Text>

              <Box
                minWidth={0}
                width="100%"
                paddingLeft={size === 'xsmall' ? 'xsmall' : 'small'}
              >
                {listItem}
              </Box>
            </Flex>
          );
        })}
      </Stack>
    </DefaultTextPropsProvider>
  );
};

// Styled components
// ------------------------------

const charWidth = 0.4;
const minCharacterWidth = [`${1 + charWidth}ch`, `${2 + charWidth}ch`];
type CharacterBulletProps = { children: ReactNode; length?: number };
const CharacterBullet = ({ children, length = 1 }: CharacterBulletProps) => (
  <span
    css={{
      display: 'inline-block',
      minWidth:
        minCharacterWidth[length - 1] ??
        minCharacterWidth[minCharacterWidth.length - 1],
      marginRight: `${-charWidth}ch`,
    }}
  >
    {children}.
  </span>
);

const textSizeToBullet = {
  xsmall: 3,
  small: 4,
  medium: 4,
  large: 6,
  xlarge: 6,
};

const Bullet = ({ size }: { size: NonNullable<TextProps['size']> }) => {
  const bulletSize = textSizeToBullet[size];
  return (
    <Box
      borderRadius="full"
      height={bulletSize}
      width={bulletSize}
      css={{ backgroundColor: 'currentColor' }}
    />
  );
};

// Utils
// ------------------------------

function numberToAlpha(inputNumber: number) {
  let returnValue = '';
  let counter = inputNumber;

  while (counter > 0) {
    const t = (counter - 1) % 26;
    returnValue = String.fromCharCode(97 + t) + returnValue;
    counter = ((counter - t) / 26) | 0;
  }

  return returnValue;
}
