export const darkThemeCSSVars = {
  ':root': {
    '--name': 'Reckon web',
    '--colors-background': '#091E42',
    '--colors-text': '#ffffff',
    '--colors-primary': '#2684FF',
    '--colors-brand': '#F84184',
    '--colors-accent': '#8777D9',
    '--colors-danger': '#de350c',
    '--colors-warning': '#ff9a1f',
    '--colors-success': '#00875a',
    '--colors-info': '#00b8d9',
    '--colors-decorative': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--colors-variant': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--border-width-standard': '1px',
    '--border-width-large': '2px',
    '--breakpoints-small': '576px',
    '--breakpoints-medium': '768px',
    '--breakpoints-large': '992px',
    '--breakpoints-xlarge': '1200px',
    '--content-width-xsmall': '480px',
    '--content-width-small': '720px',
    '--content-width-medium': '1024px',
    '--content-width-large': '1200px',
    '--content-width-xlarge': '1440px',
    '--elevation-card': '100',
    '--elevation-dropdown': '200',
    '--elevation-sticky': '300',
    '--elevation-modal': '400',
    '--elevation-popover': '500',
    '--elevation-toast': '600',
    '--shadow-xsmall': '0px 1px 2px rgba(0, 0, 0, 0.2)',
    '--shadow-small': '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '--shadow-medium': '0px 2px 8px rgba(0, 0, 0, 0.2)',
    '--shadow-large': '0px 4px 16px rgba(0, 0, 0, 0.2)',
    '--shadow-xlarge': '-8px 8px 32px rgba(0, 0, 0, 0.2)',
    '--palette-decorative': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--palette-variant': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--palette-actions-passive': '#c2c7d0',
    '--palette-actions-active': '#2684FF',
    '--palette-actions-critical': '#de350c',
    '--palette-global-accent': '#8777D9',
    '--palette-global-border': '#273959',
    '--palette-global-focus-ring': 'rgba(38,132,255,0.2)',
    '--palette-global-loading': '#2684FF',
    '--palette-border-standard': '#273959',
    '--palette-border-muted': '#182c4d',
    '--palette-border-accent': '#273366',
    '--palette-border-active': '#10366f',
    '--palette-border-cautious': '#443c3a',
    '--palette-border-critical': '#3c2435',
    '--palette-border-informative': '#074366',
    '--palette-border-positive': '#073748',
    '--palette-background-base': '#091E42',
    '--palette-background-muted': '#102548',
    '--palette-background-dim': '#182c4d',
    '--palette-background-shade': '#1f3253',
    '--palette-background-dialog': '#1d2d53',
    '--palette-background-accent': '#1d2c5a',
    '--palette-background-selectable-hover': '#0e2346',
    '--palette-background-selectable-pressed': '#13274a',
    '--palette-background-selectable-selected': '#0a224a',
    '--palette-background-selectable-selected-hover': '#0b2651',
    '--palette-background-active': '#0e2e60',
    '--palette-background-cautious': '#30323c',
    '--palette-background-critical': '#2b2239',
    '--palette-background-informative': '#08375a',
    '--palette-background-positive': '#082f46',
    '--palette-background-active-muted': '#0f3268',
    '--palette-background-cautious-muted': '#3a373b',
    '--palette-background-critical-muted': '#342337',
    '--palette-background-informative-muted': '#073d60',
    '--palette-background-positive-muted': '#073347',
    '--palette-text-base': '#ffffff',
    '--palette-text-muted': '#c2c7d0',
    '--palette-text-dim': '#848fa1',
    '--palette-text-link': '#2684FF',
    '--palette-text-link-hover': '#4593ff',
    '--palette-text-link-visited': '#8777D9',
    '--palette-text-accent': '#8777D9',
    '--palette-text-active': '#2684FF',
    '--palette-text-cautious': '#ff9a1f',
    '--palette-text-critical': '#de350c',
    '--palette-text-informative': '#00b8d9',
    '--palette-text-positive': '#00875a',
    '--palette-form-input-background': '#182c4d',
    '--palette-form-input-background-hovered': '#182c4d',
    '--palette-form-input-background-focused': '#182c4d',
    '--palette-form-input-background-disabled': '#102548',
    '--palette-form-input-background-invalid': '#2b2239',
    '--palette-form-input-border': '#182c4d',
    '--palette-form-input-border-hovered': '#273959',
    '--palette-form-input-border-focused': '#2684FF',
    '--palette-form-input-border-disabled': '#102548',
    '--palette-form-input-border-invalid': '#2b2239',
    '--palette-form-input-text': '#ffffff',
    '--palette-form-input-text-disabled': '#c2c7d0',
    '--palette-form-input-text-placeholder': '#848fa1',
    '--palette-form-input-text-invalid': '#de350c',
    '--palette-form-control-background': '#091E42',
    '--palette-form-control-background-disabled': '#273959',
    '--palette-form-control-border': '#273959',
    '--palette-form-control-interaction': '#2684FF',
    '--palette-form-control-foreground-checked': '#091E42',
    '--palette-form-control-foreground-disabled': '#848fa1',
    '--palette-segmented-control-track': '#182c4d',
    '--palette-segmented-control-divider': 'rgba(255,255,255,0.16)',
    '--palette-segmented-control-background-selected': '#2f3d64',
    '--palette-segmented-control-text': '#c2c7d0',
    '--palette-segmented-control-text-focused': '#ffffff',
    '--palette-segmented-control-text-pressed': '#ffffff',
    '--palette-segmented-control-text-selected': '#ffffff',
    '--palette-toggle-track': '#273959',
    '--palette-toggle-track-checked': '#2684FF',
    '--palette-toggle-track-disabled': '#182c4d',
    '--palette-toggle-track-disabled-checked': '#848fa1',
    '--palette-toggle-handle': 'white',
    '--palette-list-item-background-focused': '#1d3051',
    '--palette-list-item-background-pressed': '#273959',
    '--palette-list-item-background-selected': '#273959',
    '--palette-list-item-divider': '#273959',
    '--palette-list-item-text': '#ffffff',
    '--palette-list-item-text-focused': '#ffffff',
    '--palette-list-item-text-pressed': '#ffffff',
    '--palette-list-item-text-selected': '#ffffff',
    '--palette-table-row-background-cautious': '#182540',
    '--palette-table-row-background-critical': '#161f3f',
    '--palette-table-row-background-positive': '#082443',
    '--palette-table-row-background-disabled': '#E0E0E0',
    '--palette-table-row-drop-background-valid': '#AAD5FF',
    '--palette-table-row-drop-background-invalid': '#342337',
    '--palette-table-cell-border-disabled': '#939393',
    '--palette-menu-item-background-focused': '#2b3a5d',
    '--palette-menu-item-background-pressed': '#384668',
    '--palette-menu-item-background-selected': '#1e325d',
    '--palette-menu-item-divider': '#273959',
    '--palette-menu-item-text': '#ffffff',
    '--palette-menu-item-text-disabled': '#848fa1',
    '--palette-menu-item-text-focused': '#ffffff',
    '--palette-menu-item-text-pressed': '#ffffff',
    '--palette-menu-item-text-selected': '#ffffff',
    '--palette-tooltip-background': '#1d2d53',
    '--palette-tooltip-text': '#ffffff',
    '--palette-button-active-background': '#2684FF',
    '--palette-button-active-background-focused': '#378bff',
    '--palette-button-active-background-pressed': '#0079f2',
    '--palette-button-critical-background': '#de350c',
    '--palette-button-critical-background-focused': '#e73e15',
    '--palette-button-critical-background-pressed': '#d02600',
    '--palette-button-passive-background': '#c2c7d0',
    '--palette-button-passive-background-focused': '#cacfd8',
    '--palette-button-passive-background-pressed': '#b6bbc4',
    '--palette-action-button-background': '#091E42',
    '--palette-action-button-background-focused': '#182c4d',
    '--palette-action-button-background-pressed': '#273959',
    '--palette-action-button-background-selected': '#273959',
    '--palette-action-button-border': '#273959',
    '--palette-action-button-border-focused': '#304260',
    '--palette-action-button-border-pressed': '#44546f',
    '--palette-action-button-border-selected': '#44546f',
    '--palette-action-button-text': '#c2c7d0',
    '--palette-action-button-text-focused': '#ffffff',
    '--palette-action-button-text-pressed': '#ffffff',
    '--palette-action-button-text-selected': '#ffffff',
    '--palette-interactive-base-background': '#091E42',
    '--palette-interactive-base-hover': '#182c4d',
    '--palette-interactive-base-active': '#273959',
    '--radii-none': '0px',
    '--radii-xsmall': '4px',
    '--radii-small': '6px',
    '--radii-medium': '8px',
    '--radii-large': '12px',
    '--radii-full': '9999px',
    '--sizes-xsmall-font-size-display': '0.75rem',
    '--sizes-xsmall-font-size-text': '0.75rem',
    '--sizes-xsmall-border-radius': '4',
    '--sizes-xsmall-box-size': '24',
    '--sizes-xsmall-gap': '4',
    '--sizes-xsmall-padding-x': '8',
    '--sizes-xsmall-padding-y': '2',
    '--sizes-small-font-size-display': '0.75rem',
    '--sizes-small-font-size-text': '0.875rem',
    '--sizes-small-border-radius': '6',
    '--sizes-small-box-size': '32',
    '--sizes-small-gap': '8',
    '--sizes-small-padding-x': '12',
    '--sizes-small-padding-y': '4',
    '--sizes-medium-font-size-display': '1.125rem',
    '--sizes-medium-font-size-text': '1rem',
    '--sizes-medium-border-radius': '8',
    '--sizes-medium-box-size': '40',
    '--sizes-medium-gap': '8',
    '--sizes-medium-padding-x': '16',
    '--sizes-medium-padding-y': '8',
    '--sizing-xxsmall': '16px',
    '--sizing-xsmall': '24px',
    '--sizing-small': '32px',
    '--sizing-base': '40px',
    '--sizing-medium': '48px',
    '--sizing-large': '56px',
    '--sizing-xlarge': '72px',
    '--spacing-none': '0px',
    '--spacing-xxsmall': '2px',
    '--spacing-xsmall': '4px',
    '--spacing-small': '8px',
    '--spacing-medium': '12px',
    '--spacing-large': '16px',
    '--spacing-xlarge': '24px',
    '--spacing-xxlarge': '32px',
    '--typography-font-family-monospace': '"SFMono-Medium"},"SF Mono"},"Segoe UI Mono"},"Roboto Mono"},"Ubuntu Mono"}, Menlo, Consolas, Courier, monospace',
    '--typography-font-family-body': 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI"}, Roboto, "Helvetica Neue"}, sans-serif',
    '--typography-font-family-heading': 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI"}, Roboto, "Helvetica Neue"}, sans-serif',
    '--typography-font-size-xsmall': '0.75rem',
    '--typography-font-size-small': '0.875rem',
    '--typography-font-size-medium': '1rem',
    '--typography-font-size-large': '1.125rem',
    '--typography-font-size-xlarge': '1.25rem',
    '--typography-font-size-xxlarge': '1.5rem',
    '--typography-font-size-xxxlarge': '1.875rem',
    '--typography-font-size-xxxxlarge': '2.25rem',
    '--typography-font-size-xxxxxlarge': '3rem',
    '--typography-font-size-xxxxxxlarge': '4rem',
    '--typography-font-weight-regular': '400',
    '--typography-font-weight-medium': '500',
    '--typography-font-weight-semibold': '600',
    '--typography-font-weight-bold': '700',
    '--typography-font-weight-heavy': '800',
    '--typography-leading-tighter': '1',
    '--typography-leading-tight': '1.2',
    '--typography-leading-base': '1.4',
    '--typography-leading-loose': '1.6',
    '--typography-leading-looser': '1.8',
 }
} as const;