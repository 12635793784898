/** @jsx jsx */

import { Fragment, KeyboardEvent, ReactNode } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { Blanket } from '@balance-web/blanket';
import { jsx, keyframes } from '@balance-web/core';
import { Portal } from '@balance-web/portal';
import { BalanceTheme, useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';

type DialogBaseProps = {
  enforceLayout?: boolean;
  children: ReactNode;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  width?: number | string;
  elevation?: keyof BalanceTheme['elevation'];
};

const slideInAnim = keyframes({
  from: {
    transform: 'translateY(20%)',
    opacity: 0,
  },
});
const easing = 'cubic-bezier(0.2, 0, 0, 1)';

export const DialogBase = forwardRefWithAs<'div', DialogBaseProps>(
  (
    {
      as: Tag = 'div',
      enforceLayout,
      isOpen,
      onClose,
      width,
      elevation = 'modal',
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const gutter = theme.spacing.small;

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !event.defaultPrevented) {
        event.preventDefault(); // Avoid potential drawer close
        onClose();
      }
    };

    // For the `ContentDialog`. Applied here so the "body" will be contained
    // properly when `maxHeight` is reached
    const layoutStyles = enforceLayout
      ? {
          display: 'flex',
          flexDirection: 'column',
        }
      : null;

    return isOpen ? (
      <Portal>
        <Fragment>
          <Blanket animate elevation={elevation} onClick={onClose} />
          <CenterFill elevation={elevation}>
            <FocusLock
              autoFocus
              returnFocus
              lockProps={{ style: { display: 'contents' } }}
            >
              <RemoveScroll enabled forwardProps>
                <Tag
                  aria-modal="true"
                  role="dialog"
                  tabIndex={-1}
                  onKeyDown={onKeyDown}
                  ref={ref}
                  css={{
                    animation: `${slideInAnim} 320ms ${easing}`,
                    backgroundColor: theme.palette.background.dialog,
                    borderRadius: theme.radii.large,
                    boxShadow: theme.shadow.large,
                    margin: gutter,
                    maxHeight: `calc(100vh - ${gutter} * 2)`,
                    pointerEvents: 'auto',
                    transition: `transform 150ms ${easing}`,
                    width: width,
                    ...layoutStyles,
                  }}
                  {...props}
                />
              </RemoveScroll>
            </FocusLock>
          </CenterFill>
        </Fragment>
      </Portal>
    ) : null;
  }
);

const CenterFill = ({
  elevation,
  ...props
}: {
  children: ReactNode;
  elevation?: keyof BalanceTheme['elevation'];
}) => {
  const theme = useTheme();
  return (
    <div
      css={{
        // fill
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: elevation ? theme.elevation[elevation] : theme.elevation.toast,

        // center
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',

        // let clicks through to the blanket
        // reset on the dialog element
        pointerEvents: 'none',
      }}
      {...props}
    />
  );
};
