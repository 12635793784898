export const lightThemeCSSVars = {
  ':root': {
    '--colors-background': '#ffffff',
    '--colors-text': '#20262D',
    '--colors-primary': '#007AFF',
    '--colors-brand': '#F84184',
    '--colors-accent': '#3C3391',
    '--colors-danger': '#E30613',
    '--colors-warning': '#FFB027',
    '--colors-success': '#009754',
    '--colors-info': '#0091EA',
    '--colors-decorative': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--colors-variant': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--border-width-standard': '1px',
    '--border-width-large': '2px',
    '--breakpoints-small': '576px',
    '--breakpoints-medium': '768px',
    '--breakpoints-large': '882px',
    '--breakpoints-xlarge': '1200px',
    '--content-width-xsmall': '480px',
    '--content-width-small': '720px',
    '--content-width-medium': '1024px',
    '--content-width-large': '1200px',
    '--content-width-xlarge': '1440px',
    '--elevation-card': '100',
    '--elevation-dropdown': '200',
    '--elevation-sticky': '300',
    '--elevation-modal': '400',
    '--elevation-popover': '500',
    '--elevation-toast': '600',
    '--shadow-xsmall': '0px 1px 2px rgba(0, 0, 0, 0.2)',
    '--shadow-small': '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '--shadow-medium': '0px 2px 8px rgba(0, 0, 0, 0.2)',
    '--shadow-large': '0px 4px 16px rgba(0, 0, 0, 0.2)',
    '--shadow-xlarge': '-8px 8px 32px rgba(0, 0, 0, 0.2)',
    '--palette-decorative': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--palette-variant': '#BDE7FF,#B1FAE0,#FFF0B3,#FFB7B2,#FFB1D8,#CBC1F4,#AFD5FF',
    '--palette-actions-passive': '#585c62',
    '--palette-actions-active': '#007AFF',
    '--palette-actions-critical': '#E30613',
    '--palette-global-accent': '#3C3391',
    '--palette-global-border': '#e4e5e6',
    '--palette-global-focus-ring': 'rgba(0,122,255,0.2)',
    '--palette-global-loading': '#007AFF',
    '--palette-border-standard': '#e4e5e6',
    '--palette-border-muted': '#f2f2f2',
    '--palette-border-accent': '#d0cee5',
    '--palette-border-active': '#c2dfff',
    '--palette-border-cautious': '#ffeccb',
    '--palette-border-critical': '#f8c3c6',
    '--palette-border-informative': '#c2e5fa',
    '--palette-border-positive': '#c2e6d6',
    '--palette-background-base': '#ffffff',
    '--palette-background-muted': '#f8f8f9',
    '--palette-background-dim': '#f2f2f2',
    '--palette-background-shade': '#ebebec',
    '--palette-background-dialog': '#ffffff',
    '--palette-background-accent': '#e0deed',
    '--palette-background-selectable-hover': '#fbfbfb',
    '--palette-background-selectable-pressed': '#f6f6f7',
    '--palette-background-selectable-selected': '#f5faff',
    '--palette-background-selectable-selected-hover': '#ebf4ff',
    '--palette-background-active': '#d6eaff',
    '--palette-background-cautious': '#fff2dc',
    '--palette-background-critical': '#fbd7d9',
    '--palette-background-informative': '#d6edfc',
    '--palette-background-positive': '#d6eee4',
    '--palette-background-active-muted': '#cce4ff',
    '--palette-background-cautious-muted': '#ffefd4',
    '--palette-background-critical-muted': '#f9cdd0',
    '--palette-background-informative-muted': '#cce9fb',
    '--palette-background-positive-muted': '#cceadd',
    '--palette-text-base': '#20262D',
    '--palette-text-muted': '#585c62',
    '--palette-text-dim': '#909396',
    '--palette-text-link': '#007AFF',
    '--palette-text-link-hover': '#3689ff',
    '--palette-text-link-visited': '#3C3391',
    '--palette-text-accent': '#3C3391',
    '--palette-text-active': '#007AFF',
    '--palette-text-cautious': '#B35400',
    '--palette-text-critical': '#E30613',
    '--palette-text-informative': '#0091EA',
    '--palette-text-positive': '#009754',
    '--palette-form-input-background': '#ffffff',
    '--palette-form-input-background-hovered': '#f8f8f9',
    '--palette-form-input-background-focused': '#ffffff',
    '--palette-form-input-background-disabled': '#f8f8f9',
    '--palette-form-input-background-invalid': '#fbd7d9',
    '--palette-form-input-border': '#e4e5e6',
    '--palette-form-input-border-hovered': '#e4e5e6',
    '--palette-form-input-border-focused': '#1A69FE',
    '--palette-form-input-border-disabled': '#e4e5e6',
    '--palette-form-input-border-invalid': '#E30613',
    '--palette-form-input-text': '#20262D',
    '--palette-form-input-text-disabled': '#C9CBCD',
    '--palette-form-input-text-placeholder': '#C9CBCD',
    '--palette-form-input-text-invalid': '#E30613',
    '--palette-form-control-background': '#ffffff',
    '--palette-form-control-background-disabled': '#e4e5e6',
    '--palette-form-control-border': '#e4e5e6',
    '--palette-form-control-interaction': '#007AFF',
    '--palette-form-control-foreground-checked': '#ffffff',
    '--palette-form-control-foreground-disabled': '#909396',
    '--palette-segmented-control-track': '#f2f2f2',
    '--palette-segmented-control-divider': 'rgba(32,38,45,0.16)',
    '--palette-segmented-control-background-selected': '#ffffff',
    '--palette-segmented-control-text': '#585c62',
    '--palette-segmented-control-text-focused': '#20262D',
    '--palette-segmented-control-text-pressed': '#20262D',
    '--palette-segmented-control-text-selected': '#20262D',
    '--palette-toggle-track': '#e4e5e6',
    '--palette-toggle-track-checked': '#007AFF',
    '--palette-toggle-track-disabled': '#f2f2f2',
    '--palette-toggle-track-disabled-checked': '#909396',
    '--palette-toggle-handle': 'white',
    '--palette-list-item-background-focused': '#edeeee',
    '--palette-list-item-background-pressed': '#e4e5e6',
    '--palette-list-item-background-selected': '#e4e5e6',
    '--palette-list-item-divider': '#e4e5e6',
    '--palette-list-item-text': '#20262D',
    '--palette-list-item-text-focused': '#20262D',
    '--palette-list-item-text-pressed': '#20262D',
    '--palette-list-item-text-selected': '#20262D',
    '--palette-table-row-background-cautious': '#fffaf2',
    '--palette-table-row-background-critical': '#fdf0f1',
    '--palette-table-row-background-positive': '#f0f9f5',
    '--palette-table-row-background-disabled': '#E0E0E0',
    '--palette-table-row-drop-background-valid': '#AAD5FF',
    '--palette-table-row-drop-background-invalid': '#f9cdd0',
    '--palette-table-cell-border-disabled': '#939393',
    '--palette-menu-item-background-focused': '#f2f2f2',
    '--palette-menu-item-background-pressed': '#e4e5e6',
    '--palette-menu-item-background-selected': '#f0f7ff',
    '--palette-menu-item-divider': '#e4e5e6',
    '--palette-menu-item-text': '#20262D',
    '--palette-menu-item-text-disabled': '#909396',
    '--palette-menu-item-text-focused': '#20262D',
    '--palette-menu-item-text-pressed': '#20262D',
    '--palette-menu-item-text-selected': '#20262D',
    '--palette-tooltip-background': '#585c62',
    '--palette-tooltip-text': '#ffffff',
    '--palette-button-active-background': '#007AFF',
    '--palette-button-active-background-focused': '#2381ff',
    '--palette-button-active-background-pressed': '#006ff2',
    '--palette-button-critical-background': '#E30613',
    '--palette-button-critical-background-focused': '#ed1c1a',
    '--palette-button-critical-background-pressed': '#d50007',
    '--palette-button-passive-background': '#585c62',
    '--palette-button-passive-background-focused': '#5f6369',
    '--palette-button-passive-background-pressed': '#4e5258',
    '--palette-action-button-background': '#ffffff',
    '--palette-action-button-background-focused': '#f2f2f2',
    '--palette-action-button-background-pressed': '#e4e5e6',
    '--palette-action-button-background-selected': '#e4e5e6',
    '--palette-action-button-border': '#e4e5e6',
    '--palette-action-button-border-focused': '#dbdcdd',
    '--palette-action-button-border-pressed': '#c9cbcd',
    '--palette-action-button-border-selected': '#c9cbcd',
    '--palette-action-button-text': '#585c62',
    '--palette-action-button-text-focused': '#20262D',
    '--palette-action-button-text-pressed': '#20262D',
    '--palette-action-button-text-selected': '#20262D',
    '--palette-interactive-base-background': '#ffffff',
    '--palette-interactive-base-hover': '#f2f2f2',
    '--palette-interactive-base-active': '#e4e5e6',
    '--radii-none': '0px',
    '--radii-xsmall': '4px',
    '--radii-small': '6px',
    '--radii-medium': '8px',
    '--radii-large': '12px',
    '--radii-full': '999px',
    '--sizes-xsmall-font-size-display': '0.75rem',
    '--sizes-xsmall-font-size-text': '0.75rem',
    '--sizes-xsmall-border-radius': '4px',
    '--sizes-xsmall-box-size': '24px',
    '--sizes-xsmall-gap': '4px',
    '--sizes-xsmall-padding-x': '8px',
    '--sizes-xsmall-padding-y': '2px',
    '--sizes-small-font-size-display': '0.75rem',
    '--sizes-small-font-size-text': '0.875rem',
    '--sizes-small-border-radius': '6px',
    '--sizes-small-box-size': '32px',
    '--sizes-small-gap': '8px',
    '--sizes-small-padding-x': '12px',
    '--sizes-small-padding-y': '4px',
    '--sizes-medium-font-size-display': '1.125rem',
    '--sizes-medium-font-size-text': '1rem',
    '--sizes-medium-border-radius': '8px',
    '--sizes-medium-box-size': '40px',
    '--sizes-medium-gap': '8px',
    '--sizes-medium-padding-x': '16px',
    '--sizes-medium-padding-y': '8px',
    '--sizing-xxsmall': '16px',
    '--sizing-xsmall': '24px',
    '--sizing-small': '32px',
    '--sizing-base': '40px',
    '--sizing-medium': '48px',
    '--sizing-large': '56px',
    '--sizing-xlarge': '72px',
    '--spacing-none': '0px',
    '--spacing-xxsmall': '2px',
    '--spacing-xsmall': '4px',
    '--spacing-small': '8px',
    '--spacing-medium': '12px',
    '--spacing-large': '16px',
    '--spacing-xlarge': '24px',
    '--spacing-xxlarge': '32px',
    '--typography-font-weight-regular': '400',
    '--typography-font-weight-medium': '500',
    '--typography-font-weight-semibold': '600',
    '--typography-font-weight-bold': '700',
    '--typography-font-weight-heavy': '800',
    '--typography-leading-tighter': '1',
    '--typography-leading-tight': '1.2',
    '--typography-leading-base': '1.4',
    '--typography-leading-loose': '1.6',
    '--typography-leading-looser': '1.8',
 }
} as const;